import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/content/pas-doptimisme-resigne.mdx";
import React from "react";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import Header from "../../components/Header";
import shortcodes from "../../components/mdxComponents";
function PageTemplate({data, children}) {
  return React.createElement("article", {
    className: "flex flex-col"
  }, React.createElement("div", {
    className: "pl-4"
  }, React.createElement("div", {
    className: "text-sm"
  }, "On ", data.mdx.frontmatter.date), React.createElement("div", {
    className: "text-sm"
  }, "By ", data.site.siteMetadata.author)), React.createElement(Header, {
    bgImageData: data.imageSharp?.fluid || null
  }, React.createElement("div", null, React.createElement("h1", null, data.mdx.frontmatter.title))), React.createElement("div", {
    className: "flex bg-deep-black"
  }, React.createElement("div", {
    className: "content type-news"
  }, React.createElement(MDXProvider, {
    components: shortcodes,
    className: "serif w-2/4"
  }, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data}) => {
  return React.createElement("title", null, data.mdx.frontmatter.title, data.mdx.frontmatter.categories?.length > 0 ? " - " + data.mdx.frontmatter.categories[0] : null, " | ", data.site.siteMetadata.title);
};
export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        categories
        date
      }
    }
    site {
      siteMetadata {
        description
        siteUrl
        title
        author
      }
    }
  }
`;
